const slot303="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988952/Jilli/slot303_bgom0z.png"
const slot301="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988952/Jilli/slot301_xgs8vs.png"
const slot252="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988951/Jilli/slot252_u6kswn.png"
const slot238="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988947/Jilli/slot238_najjnb.png"
const slot239="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988947/Jilli/slot239_np9ydq.png"
const slot230="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988947/Jilli/slot230_kerxru.png"
const slot225="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988947/Jilli/slot225_buuwip.png"
const slot223="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988946/Jilli/slot223_h95wib.png"
const slot214="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988946/Jilli/slot214_swgdob.png"
const slot209="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988946/Jilli/slot209_gacfqk.png"
const slot193="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988941/Jilli/slot193_p4fxeg.png"
const slot198="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988941/Jilli/slot198_bemny0.png"
const slot183="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988941/Jilli/slot183_rgasnu.png"
const slot181="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988941/Jilli/slot181_vzyd6k.png"
const slot191="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988941/Jilli/slot191_hbnwr5.png"
const slot176="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988941/Jilli/slot176_tjvcy9.png"
const slot172="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988940/Jilli/slot172_zaib4o.png"
const slot171="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988940/Jilli/slot171_kj6tgh.png"
const slot166="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988936/Jilli/slot166_btb98b.png"
const slot164="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot164_vafdtt.png"
const slot146="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot146_elxb7h.png"
const slot153="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot153_ztvmdf.png"
const slot144="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot144_msrj8r.png"
const slot145="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot145_k1zztg.png"
const slot137="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot137_pgyk1b.png"
const slot142="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988935/Jilli/slot142_alykzc.png"
const slot136="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988930/Jilli/slot136_czhlsj.png"
const slot135="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988930/Jilli/slot135_y0zxhj.png"
const slot126="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988929/Jilli/slot126_gos0ky.png"
const slot130="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988929/Jilli/slot130_hpwk2q.png"
const slot134="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988929/Jilli/slot134_zfopjc.png"
const slot115="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988929/Jilli/slot115_izbblp.png"
const slot116="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988929/Jilli/slot116_tzt423.png"
const slot109="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988928/Jilli/slot109_k268aa.png"
const slot110="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988928/Jilli/slot110_qtdkfz.png"
const slot108="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988923/Jilli/slot108_xidy0x.png"
const slot103="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988923/Jilli/slot103_rjbt19.png"
const slot106="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988923/Jilli/slot106_iz4gzv.png"
const slot102="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988923/Jilli/slot102_whjcel.png"
const slot100="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988923/Jilli/slot100_vwflxz.png"
const slot101="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988923/Jilli/slot101_hn6ibu.png"
const slot92="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988922/Jilli/slot92_qx0pro.png"
const slot91="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988922/Jilli/slot91_i8w9ki.png"
const slot87="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988922/Jilli/slot87_u1o3pz.png"
const slot85="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988918/Jilli/slot85_mn5nap.png"
const slot78="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988917/Jilli/slot78_sg1jub.png"
const slot77="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988917/Jilli/slot77_phhhey.png"
const slot51="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988916/Jilli/slot51_cvoalz.png"
const slot66="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988916/Jilli/slot66_x1khns.png"
const slot76="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988916/Jilli/slot76_jwxvos.png"
const slot58="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988916/Jilli/slot58_z0cbpi.png"
const slot49="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988916/Jilli/slot49_kgesjj.png"
const slot48="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988916/Jilli/slot48_pafe8c.png"
const slot47="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988915/Jilli/slot47_mastyz.png"
const slot46="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988912/Jilli/slot46_dpnroi.png"
const slot40="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988911/Jilli/slot40_uhiu10.png"
const slot43="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988911/Jilli/slot43_akoprr.png"
const slot45="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988911/Jilli/slot45_ptc1ao.png"
const slot38="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988911/Jilli/slot38_wigpab.png"
const slot36="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988911/Jilli/slot36_zrgbws.png"
const slot35="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988910/Jilli/slot35_cyuxiq.png"
const slot33="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988910/Jilli/slot33_cpqwsj.png"
const slot27="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988906/Jilli/slot27_qg4lhj.png"
const slot30="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988906/Jilli/slot30_stnrc9.png"
const slot23="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988906/Jilli/slot23_eaphkn.png"
const slot17="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988906/Jilli/slot17_alagmd.png"
const slot21="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988906/Jilli/slot21_wekz2b.png"
const slot16="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988905/Jilli/slot16_ymktlr.png"
const slot9="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988905/Jilli/slot9_kjucrk.png"
const slot8="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988905/Jilli/slot8_lb0n7a.png"
const slot7="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988896/Jilli/slot7_j1wiz0.png"
const slot6="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988896/Jilli/slot6_tisajr.png"
const slot2="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988895/Jilli/slot2_ftuhhe.png"
const rummy253="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988895/Jilli/rummy253_yb1xat.png"
const rummy220="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988895/Jilli/rummy220_wd3qox.png"
const rummy211="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988894/Jilli/rummy211_vyzwgk.png"
const rummy231="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988895/Jilli/rummy231_gzhuth.png"
const rummy221="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988895/Jilli/rummy221_nekhog.png"
const rummy219="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988894/Jilli/rummy219_vqpxpp.png"
const rummy160="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988892/Jilli/rummy160_vykdca.png"
const rummy159="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988881/Jilli/rummy159_gngebu.png"
const rummy127="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988881/Jilli/rummy127_o0hsrm.png"
const rummy75="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988880/Jilli/rummy75_k8fovz.png"
const rummy94="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988880/Jilli/rummy94_tsltvh.png"
const rummy79="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988880/Jilli/rummy79_tqknji.png"
const rummy72="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988880/Jilli/rummy72_gwunry.png"
const jillihote3="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988880/Jilli/jillihote3_kmybmi.png"
const jillihote2="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988879/Jilli/jillihote2_memv50.png"
const jillihote1="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988875/Jilli/jillihote1_chbxki.png"
const jillihot3="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988875/Jilli/jillihot3_f08kxp.png"
const jilli7="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988875/Jilli/jilli7_l3qgpf.png"
const jilli1="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988874/Jilli/jilli1_yigr4t.png"
const jilli6="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988875/Jilli/jilli6_nfofu4.png"
const jilli4="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988875/Jilli/jilli4_vdmqms.png"
const jilli5="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988875/Jilli/jilli5_dk13ng.png"
const jilli3="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988874/Jilli/jilli3_riyahw.png"
const jilli2="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988874/Jilli/jilli2_urqwib.png"
const fishing882="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988870/Jilli/fishing882_mawsdn.png"
const fishing212="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988869/Jilli/fishing212_ghq9zd.png"
const fishing119="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988869/Jilli/fishing119_cjhcqh.png"
const fishing1="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988869/Jilli/fishing1_munzas.png"
const fishing60="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988869/Jilli/fishing60_vliegn.png"
const fishing74="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988869/Jilli/fishing74_bghy7x.png"
const fishing71="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988869/Jilli/fishing71_ttpa0k.png"
const fishing42="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988868/Jilli/fishing42_qoloth.png"
const fishing32="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988868/Jilli/fishing32_rxqknu.png"
const fishing20="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988868/Jilli/fishing20_hlrobi.png"
const crash261="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988867/Jilli/crash261_key036.png"
const crash254="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988863/Jilli/crash254_iwqe8t.png"
const crash242="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988863/Jilli/crash242_yjvusz.png"
const crash241="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988863/Jilli/crash241_bkoe0b.png"
const crash236="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988863/Jilli/crash236_jfq4mu.png"
const crash235="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988862/Jilli/crash235_z7hsux.png"
const crash232="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988862/Jilli/crash232_mdshpc.png"
const crash233="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988862/Jilli/crash233_ckigki.png"
const crash229="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988862/Jilli/crash229_l5r7ad.png"
const crash224="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988862/Jilli/crash224_ldyars.png"
const casino217="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988862/Jilli/casino217_pljfod.png"
const casino216="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988861/Jilli/casino216_gvb4oc.png"
const casino204="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988858/Jilli/casino204_yudrc2.png"
const casino197="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988857/Jilli/casino197_zmfi01.png"
const casino200="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988857/Jilli/casino200_ryjbyq.png"
const casino195="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988857/Jilli/casino195_um7ous.png"
const casino182="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988857/Jilli/casino182_akupla.png"
const casino179="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988857/Jilli/casino179_qgugvq.png"
const casino178="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988857/Jilli/casino178_e6xb9k.png"
const casino173="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988856/Jilli/casino173_wiwwrm.png"
const casino177="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988856/Jilli/casino177_xnwtx0.png"
const casino151="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988856/Jilli/casino151_p3vtdx.png"
const casino152="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988856/Jilli/casino152_l81rlc.png"
const casino150="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988856/Jilli/casino150_tgcuow.png"
const casino149="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988855/Jilli/casino149_gvc5zv.png"
const casino148="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988855/Jilli/casino148_wgirwy.png"
const casino111="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988855/Jilli/casino111_j1x2sw.png"
const casino125="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988855/Jilli/casino125_kav7gm.png"
const casino139="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino139_irw4bp.png"
const casino112="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino112_jhadac.png"
// const casino112="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino112_jhadac.png"
const casino118="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino118_pmtm0s.png"
const casino113="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino113_u4yhtn.png"
const casino124="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino124_xlbybt.png"
const casino123="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino123_ksg5dm.png"
const casino61="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988854/Jilli/casino61_gwx9vf.png"
const casino63="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988853/Jilli/casino63_vzijfk.png"
const casino122="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988853/Jilli/casino122_qhpkaw.png"
const casino62="https://res.cloudinary.com/djkkjx9ry/image/upload/v1725988853/Jilli/casino62_x8asoc.png"

export const Casino=[
    casino217,
    casino216,
    casino204,
    casino197,
    casino200,
    casino195,
    casino182,
    casino179,
    casino178,
    casino173,
    casino177,
    casino151,
    casino152,
    casino150,
    casino149,
    casino148,
    casino111,
    casino125,
    casino139,
    casino112,
    casino118,
    casino113,
    casino124,
    casino123,
    casino61,
    casino63,
    casino122,
    casino62

]
export const Crash=[
    crash261,
    crash254,
    crash242,
    crash241,
    crash236,
    crash235,
    crash232,
    crash233,
    crash229,
    crash224
]
export const Fishing=[
fishing882,
fishing212,
fishing119,
fishing1,
fishing60,
fishing74,
fishing71,
fishing42,
fishing32,
fishing20
]
export const Jilli=[
     jilli7,
     jilli1,
     jilli6,
     jilli4,
     jilli5,
     jilli3,
     jilli2
]
export const Rummy=[
    rummy253,
    rummy220,
    rummy211,
    rummy231,
    rummy221,
    rummy219,
    rummy160,
    rummy159,
    rummy127,
    rummy75,
    rummy94,
    rummy79,
    rummy72
]
export const Slots=[
    slot303,
    slot301,
    slot252,
    slot238,
    slot239,
    slot230,
    slot225,
    slot223,
    slot214,
    slot209,
    slot193,
    slot198,
    slot183,
    slot181,
    slot191,
    slot176,
    slot172,
    slot171,
    slot166,
    slot164,
    slot146,
    slot153,
    slot144,
    slot145,
    slot137,
    slot142,
    slot136,
    slot135,
    slot126,
    slot130,
    slot134,
    slot115,
    slot116,
    slot109,
    slot110,
    slot108,
    slot103,
    slot106,
    slot102,
    slot100,
    slot101,
    slot92,
    slot91,
    slot87,
    slot85,
    slot78,
    slot77,
    slot51,
    slot66,
    slot76,
    slot58,
    slot49,
    slot48,
    slot47,
    slot46,
    slot40,
    slot43,
    slot45,
    slot38,
    slot36,
    slot35,
    slot33,
    slot27,
    slot30,
    slot23,
    slot17,
    slot21,
    slot16,
    slot9,
    slot8,
    slot7,
    slot6,
    slot2
]