import HotNew1 from "../../assets/hotnew1.png"
import jillihote1 from "../../assets/jilli/jillihote1.png"
import jillihote2 from "../../assets/jilli/jillihote2.png"
import jillihote3 from "../../assets/jilli/jillihote3.png"
import Jilli1 from "../../assets/jilli/jilli1.png"
import Jilli2 from "../../assets/jilli/jilli2.png"
import Jilli3 from "../../assets/jilli/jilli3.png"
import Jilli4 from "../../assets/jilli/jilli4.png"
import Jilli5 from "../../assets/jilli/jilli5.png"
import Jilli6 from "../../assets/jilli/jilli6.png"

const OriginalImg1 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875778/GameAssets/aviator_xfyoca.png"
const OriginalImg2 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875877/GameAssets/plinko_wlkzpj.png"
const OriginalImg3 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875867/GameAssets/limbo_tqaazj.png"
const OriginalImg4 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875932/GameAssets/wingo2_oz6hgo.png"
const OriginalImg5 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875847/GameAssets/hourse-racing_qz0vfr.jpg"
const OriginalImg6 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875923/GameAssets/triple_pbvb0h.jpg"
const OriginalImg7 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875826/GameAssets/cryptos_crnicd.jpg"
const OriginalImg8 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875806/GameAssets/coin-flip_igyj87.jpg"
const OriginalImg9 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875848/GameAssets/hotline_zx6ymg.jpg"
const OriginalImg10 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875866/GameAssets/keno_ty6ymp.jpg"
const OriginalImg11 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875834/GameAssets/goal_c8hq44.jpg"
const OriginalImg12 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875873/GameAssets/miniroulette_l9uay3.jpg"
const OriginalImg13 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875829/GameAssets/dice_awwpmv.jpg"
const OriginalImg14 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875839/GameAssets/hilo_uid9zp.jpg"
const OriginalImg15 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875871/GameAssets/mines_qc6z6i.jpg"
const OriginalImg16 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875866/GameAssets/keno80_emss4r.jpg"
const OriginalImg17 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875779/GameAssets/aviator2_uatceq.png"
const OriginalImg18 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875828/GameAssets/dice2_nwftd8.png"
const OriginalImg19 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875842/GameAssets/goal2_yaahjt.png"
const OriginalImg20 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875876/GameAssets/plinko2_itcss8.png"
const OriginalImg21 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875872/GameAssets/mines2_w7hzx5.png"
const OriginalImg22 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875843/GameAssets/hilo2_c6dxbo.png"
const OriginalImg23 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875866/GameAssets/keno2_ctsyot.png"
const OriginalImg24 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875873/GameAssets/mini-roulette2_yidhs5.jpg"
const OriginalImg25 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875807/GameAssets/color-game_ekzibv.png"
const OriginalImg26 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875875/GameAssets/pappu_malbgb.png"

const HotImg1 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875842/GameAssets/hotimg1_nxhgu8.png"
const HotImg2 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875842/GameAssets/hotimg2_bvxwnc.png"
const HotImg3 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875847/GameAssets/hotimg3_riztpx.png"
const HotImg4 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875844/GameAssets/hotimg4_gsyvw3.png"
const HotImg5 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875844/GameAssets/hotimg5_xgd8is.png"
const HotImg6 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875847/GameAssets/hotimg6_cqx7af.png"


const CasinoImg1 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875801/GameAssets/casinoimg1_jj1jbc.png"
const CasinoImg2 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875801/GameAssets/casinoimg2_tzfjcj.png"
const CasinoImg3 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875801/GameAssets/casinoimg3_tkkigd.png"
const CasinoImg4 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875802/GameAssets/casinoimg4_gwnwsj.png"
const CasinoImg5 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875802/GameAssets/casinoimg5_hp4msj.png"
const CasinoImg6 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875804/GameAssets/casinoimg6_pny5gc.png"


const SlotsImg1 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875883/GameAssets/slotsimg1_gixim9.png"
const SlotsImg2 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875883/GameAssets/slotsimg2_ktob6j.png"
const SlotsImg3 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875884/GameAssets/slotsimg3_wdq1dc.png"
const SlotsImg4 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875884/GameAssets/slotsimg4_llrl0c.png"
const SlotsImg5 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875888/GameAssets/slotsimg5_mtohln.png"
const SlotsImg6 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875888/GameAssets/slotsimg6_y9hpvt.png"
const SlotsImg7 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875889/GameAssets/slotsimg7_ncbst4.png"
const SlotsImg8 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875896/GameAssets/slotsimg8_elqcuw.png"
const SlotsImg9 = "https://res.cloudinary.com/djkkjx9ry/image/upload/v1723875898/GameAssets/slotsimg9_lcy6vf.png"

export const originalData=[
    {
    Img1:OriginalImg1,
  Img2:OriginalImg2,
  Img3:OriginalImg3
    },
   
    
      {
    Img1:OriginalImg13,
  Img2:OriginalImg14,
  Img3:OriginalImg15
    },
      {
    Img1:OriginalImg16,
  Img2:OriginalImg17,
  Img3:OriginalImg18
    },
      {
    Img1:OriginalImg19,
  Img2:OriginalImg20,
  Img3:OriginalImg21
    },
      {
    Img1:OriginalImg22,
  Img2:OriginalImg23,
  Img3:OriginalImg24
    },
      {
    Img1:OriginalImg25,
  Img2:OriginalImg26,
     },
    
   
  ]
  export const HotGameData=[
{
Img:HotNew1,
},
{
Img:HotImg2,
},
{
Img:HotImg3,
},
{
Img:jillihote1,
},
{
Img:jillihote2,
},
{
Img:jillihote3,
},
  ]

  export const CasinoGameData=[
{
Img:Jilli1,
},
{
Img:Jilli2,
},
{
Img:Jilli3,
},
{
Img:Jilli4,
},
{
Img:Jilli5,
},
{
Img:Jilli6,
},
  ]

  export const SlotsGameData=[
    {
        Img1:SlotsImg1,
      Img2:SlotsImg2,
      Img3:SlotsImg3,
      Img4:SlotsImg4,
      Img5:SlotsImg5,
      Img6:SlotsImg6,
        },
    {
        Img1:SlotsImg7,
      Img2:SlotsImg8,
      Img3:SlotsImg9,
     
        },
  ]